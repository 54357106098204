import type { Schemas } from '#shopware'
import type { StatamicNavigationItem } from '~/types/statamic'

export type NavigationItem = Schemas['Category'] | StatamicNavigationItem

export const useNavigationDropdown = defineStore('navigationDropdown', () => {
  const openItem = ref<NavigationItem | string | null>(null)
  const children = ref<NavigationItem[]>([])
  const dropdownX = ref<number | undefined>(undefined)
  const dropdownNippleX = ref<number | undefined>(undefined)
  const timeout = ref()

  const open = (
    item: NavigationItem | string,
    x?: number | undefined,
    nippleX?: number | undefined
  ) => {
    openItem.value = item
    dropdownX.value = x
    dropdownNippleX.value = nippleX
    if (typeof item !== 'string') {
      children.value = item.children as NavigationItem[]
    } else {
      children.value = []
    }
  }

  const close = () => {
    timeout.value = setTimeout(() => {
      openItem.value = null
      children.value = []
      dropdownX.value = undefined
      dropdownNippleX.value = undefined
    }, 200)
  }

  const clearCloseDelay = () => {
    if (timeout.value) {
      clearTimeout(timeout.value)
    }
  }

  return {
    active: openItem,
    children,
    dropdownX,
    dropdownNippleX,
    open,
    close,
    clearCloseDelay,
  }
})
