<template>
  <nav role="navigation">
    <Container indent="none" class="hidden xl:block">
      <div class="relative flex items-center justify-between bg-black p-4">
        <NuxtLink :to="localePath('/')">
          <Logo class="h-8 lg:h-auto lg:w-32 2xl:h-8 2xl:w-auto" white />
        </NuxtLink>

        <!-- Left Side -->
        <PageNavigation class="grow self-stretch 2xl:mx-6" />

        <!-- Right Side -->
        <div class="flex items-center gap-4">
          <div class="min-w-24 max-2xl:max-w-40 2xl:max-w-48">
            <ShopSharedProductSearch :dark="true" @select="onSearchSelect" />
          </div>

          <SecondaryNavigation />
        </div>
      </div>
      <Notifications />
    </Container>

    <div
      class="flex h-16 items-center justify-between gap-2 bg-black p-4 xl:hidden"
    >
      <NuxtLink :to="localePath('/')" class="relative z-top basis-1/3">
        <Logo class="h-6" white />
      </NuxtLink>
      <SecondaryNavigation class="grow" />
    </div>

    <Notifications class="xl:hidden" />
  </nav>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const localePath = useLocalePath()
const router = useRouter()
const { getProductRoute } = useShopRouter()

const onSearchSelect = (product: Schemas['Product']) => {
  router.push(getProductRoute(product))
}
</script>
