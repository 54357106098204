<template>
  <div
    class="absolute top-full z-top pt-4 shadow-2xl transition-all duration-300"
  >
    <svg
      viewBox="0 0 29 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute w-7 -translate-y-full"
      :class="[
        darkNipple ? 'text-black' : 'text-white',
        !nippleOffsetLeft ? { 'left-3': !end, 'right-7': end } : '',
      ]"
      :style="nippleStyles"
    >
      <path d="M15 0L0 13H28.75L15 0Z" fill="currentColor" />
    </svg>
    <div class="min-w-[16.25rem] bg-white text-black">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    darkNipple?: boolean
    nippleOffsetLeft?: string
    end?: boolean
  }>(),
  {
    darkNipple: false,
    start: false,
    end: false,
  }
)

const nippleStyles = computed(() => {
  const data: any = {}
  if (props.nippleOffsetLeft) {
    data.left = props.nippleOffsetLeft
  }
  return data
})
</script>
