<template>
  <div class="flex items-end">
    <button class="hidden xl:inline-block" @mouseenter="open">
      <IconHamburger class="w-5" />
    </button>
    <button class="xl:hidden" @click="open">
      <Transition mode="out-in" name="fade">
        <IconHamburger v-if="!isOpenMobileMenu" class="w-5" />
        <IconClose v-else class="w-5" />
      </Transition>
    </button>

    <NavDropdown
      v-show="isOpen"
      end
      class="right-0"
      @mouseleave="navigationDropdown.close()"
      @mouseenter="navigationDropdown.clearCloseDelay()"
    >
      <div class="flex space-x-12 px-10 pb-9 pt-8">
        <ul
          v-for="(navItemLevel1, index) in navItems"
          :key="index"
          class="flex flex-col space-y-2.5 font-medium"
        >
          <li class="font-bold">
            <NuxtLink :to="navItemLevel1.page.url">
              {{ navItemLevel1.page.title }}
            </NuxtLink>
          </li>
          <li
            v-for="(navItemLevel2, secondIndex) in navItemLevel1.children"
            :key="secondIndex"
          >
            <NuxtLink :to="navItemLevel2.page.url">
              {{ navItemLevel2.page.title }}
            </NuxtLink>
          </li>

          <li
            v-if="general && index === navItems?.length - 1"
            class="flex flex-1 flex-col justify-end pt-6"
          >
            <p class="font-bold">Support</p>

            <div v-if="general?.phone" class="mt-4 flex items-center">
              <IconPhone class="mr-2.5 w-4" />
              <a :href="`tel:${general?.phone}`" class="font-bold">
                {{ general?.phone }}
              </a>
            </div>
            <div v-if="general?.email" class="mt-1 flex items-center">
              <IconEmail class="mr-2.5 w-4" />
              <a :href="`mailto:${general?.email}`" class="font-bold">
                {{ general?.email }}
              </a>
            </div>
          </li>
        </ul>

        <ClientOnly>
          <LangSwitcher />
        </ClientOnly>
      </div>
    </NavDropdown>

    <!-- Mobile -->
    <Teleport to="body">
      <Transition name="slide-down">
        <MobileMenu v-show="isOpenMobileMenu" />
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const globals = useCmsGlobals()
const isOpenMobileMenu = ref(false)
const { smaller } = useBreakpoints(breakpointsTailwind)
const isMobile = smaller('xl')

const navigationDropdown = useNavigationDropdown()
const { active } = storeToRefs(navigationDropdown)
const locked = useScrollLock(window)

const general = computed(() => globals.value.general)
const isOpen = computed(() => active.value === 'hamburger')

const navItems = computed(() => {
  const links = globals.value.main?.tree?.filter(({ page }) => page.is_menu)

  if (!links?.length) return []

  return links[0].children
})

const open = () => {
  if (isMobile.value) {
    // mobile menu
    isOpenMobileMenu.value = !isOpenMobileMenu.value
    locked.value = isOpenMobileMenu.value
    return isOpenMobileMenu.value
  }

  navigationDropdown.open('hamburger')
}
const router = useRouter()
router.beforeResolve(() => {
  navigationDropdown.close()
  isOpenMobileMenu.value = false
  locked.value = false
})
</script>
