<template>
  <div class="relative flex flex-col items-start">
    <ul
      class="flex space-y-2.5 lg:flex-col"
      :aria-label="$t('switch_language')"
    >
      <li class="hidden lg:inline-block">
        <span class="font-bold">{{ $t('language') }}</span>
      </li>
      <li
        v-for="option in options"
        :key="option.code"
        class="mr-3 uppercase lg:mr-0"
        :class="{ 'font-bold': option.code === currentLocale }"
      >
        <button @click="switchLanguage(option)">
          <span class="hidden lg:inline">{{ $t(option.code) }}</span>
          <span class="uppercase lg:hidden">{{ option.code }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

type Alternate = {
  locale: string
  permalink: string
  url: string
}

type LanguageSwitchOption = LocaleObject & {
  languageId?: string
  alternate?: Alternate
}

withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  }
)

const switchLocalePath = useSwitchLocalePath()
const { locale: currentLocale } = useI18n()
const { locales } = useI18n()
const alternates = useAlternates()

const options = computed(() => {
  const items = locales.value as (LocaleObject & { languageId?: string })[]
  return items.map((locale) => {
    return {
      ...locale,
      alternate: alternates.value.find(
        (alternate) => alternate.locale === locale.code
      ),
    }
  })
})

const switchLanguage = async (option: LanguageSwitchOption) => {
  if (option.alternate) {
    return navigateTo(option.alternate.url)
  }

  navigateTo(switchLocalePath(option.code))
}
</script>
