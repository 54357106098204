<template>
  <div class="flex items-center text-sm text-white max-xl:justify-end">
    <AuthUserNavItem />
    <AppLink
      :to="getShopRoute('/wishlist')"
      icon="heart"
      icon-classes="text-white m-0 size-5"
      class="px-2 lg:px-4"
    />
    <ShopSharedCart />
    <HamburgerMenu class="pl-2 md:ml-4 lg:px-4" />
  </div>
</template>

<script setup lang="ts">
const { getShopRoute } = useShopRouter()
</script>
